@import "../../../../scss/variables.scss";

.expanded {
  max-height: 9rem;
}

.shrinked {
  max-height: 2.375rem;
}

.expanded--desktop {
  top: 3rem;
  max-height: 9rem;
  border: 1px solid $border-primary;
  z-index: 1;
}

.shrinked--desktop {
  top: 1rem;
  max-height: 0;
  z-index: -2;
}

.vertical-separator {
  width: .125rem;
  height: 2rem;
}