@import "../../scss/variables.scss";

// Local Placeholders
%hs-form {
  display: flex;
  justify-content: center;
  height: 2.5rem;
}

// Local Maps
$form-grids: ("md": 1rem, "lg": 4rem);

// Local Mixins
@mixin error-display-between($right) {
  .hs_error_rollup .hs-error-msgs {
    &::after {
      position: absolute;
      top: 0.5rem;
      right: $right;
      color: $error;
      font-family: 'Font Awesome\ 5 Free';
      font-size: 1rem;
      font-weight: 900;
      content: "\f06a";
    }
  }
}

@mixin error-display-default {
  .hs_error_rollup .hs-error-msgs {
    &::after {
      position: unset;
      font-family: inherit;
      font-weight: normal;
      font-size: 0.875rem;
      color: $error;
      text-align: center;
      content: 'Bitte ändern Sie Ihre E-Mail-Adresse';
      margin-bottom: 0.5rem;
    }
  }
}