@import '../../../scss/variables.scss';

// Local Maps
$button-heights: (
  'small': 2rem,
  'default': 2.5rem,
  'large': 3rem,
  'xlarge': 3.75rem
);

// Local Placeholders
%pointer-btn-pseudo-elements {
  position: absolute;
  content: '';
  bottom: 0;
  width: 0;
  height: 0;
  border-top: 1.85rem solid transparent;
  border-bottom: 1.85rem solid transparent;
}

// Utility Class Definitions
@each $size, $height in $button-heights {
  .btn-#{$size} {
    height: $height;
    padding: 0 ($height/5);
  }
}

.pointer-btn {
  width: 10rem;
  border: none !important;
  border-radius: 0 !important;

  &:hover {
    background-color: $primary !important;
  }

  &::before {
    @extend %pointer-btn-pseudo-elements;
    left: 0;
    border-left: 1.25rem solid $ternary;
  }

  &::after {
    @extend %pointer-btn-pseudo-elements;
    right: -1.25rem;
    border-left: 1.25rem solid $primary;
  }
}

.marked-element {
  height: 100%;
  width: 100%;
  padding: 0;
  background-color: transparent;
}

.btn:focus {
  // remove default button box-shadow from Bootstrap button. (applicable for Bootstrap v.4 or higher)
  box-shadow: none !important;
}

.btn-outline-danger {
  &:hover {
    color: white !important;
  }
}
