@import './util.scss';

.mobile-nav-menu {
  background-color: #2e343f;
  top: 0;
  width: 70%;
  height: 100vh;
  border-left: 10px solid $primary;
  transition: right 0.5s;
  z-index: 1035;
  padding: 48px 0.5rem 0.5rem;

  .icon-times {
    opacity: 0.5;
  }
}

.roadjet-logo--white {
  width: 12.375rem;
  height: 3rem;
}

.user-image {
  height: 64px;
  width: 64px;
}

.user-email-abbr {
  height: 64px;
  width: 64px;
  line-height: 64px;
}
