@import "./util.scss";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.page-title-wrapper {
  @extend %title-wrapper;
  height: 7rem;

  .page-title--single-line {
    @include title-line(1.5rem);
  }

  @each $index, $top in $title-line--mobile {
    .page-title--multi-line--#{$index} {
      @include title-line($top);
    }
  }
}

@include media-breakpoint-up(md) {
  .page-title-wrapper {
    .page-title--single-line {
      font-size: 3.75rem;
    }

    @each $index, $top in $title-line--tablet {
      .page-title--multi-line--#{$index} {
        @include title-line($top);
        font-size: 3rem;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .page-title-wrapper {
    .page-title--single-line {
      top: 2.5rem;
    }

    @each $index, $top in $title-line--desktop {
      .page-title--multi-line--#{$index} {
        @include title-line($top);
        font-size: 3.75rem;
      }
    }
  } 
}

@each $breakpoint, $height in $breakpoint-tiers {
  @media(min-width: #{$breakpoint}px) {
    .page-title-wrapper {
      height: $height
    }
  }
}

