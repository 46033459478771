// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.page-title-bg-alt {
  max-height: 7rem;
  top: 3rem;
}

@include media-breakpoint-up(md) {
  .page-title-bg-alt {
    max-height: 9rem;
  }
}

@include media-breakpoint-up(lg) {
  .page-title-bg-alt {
    max-height: 10.5rem;
  }
}

@media(min-width: 1600px) {
  .page-title-bg-alt {
    max-height: 18rem;
    top: 7rem;
  }
}