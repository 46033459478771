@import '../../../scss/variables.scss';

// Local Maps
$text-input-sizes: (
  'sm': 2.25rem,
  'md': 2.75rem,
  'lg': 3rem
);
$radio-input-sizes: (
  'sm': 0.75rem,
  'md': 0.9rem,
  'lg': 1rem,
  'xl': 1.25rem
);
$checkbox-input-sizes: (
  'sm': 0.75rem,
  'md': 1rem,
  'lg': 1.25rem
);
$checkbox-icon-placement-and-sizes: (
  'sm': (
    -0.6rem,
    -0.35rem,
    1rem
  ),
  'md': (
    -0.7rem,
    -0.45rem,
    1.25rem
  ),
  'lg': (
    -0.8rem,
    -0.5rem,
    1.5rem
  )
);

// Local Mixins
@mixin checkbox-icon($top, $right, $font-size) {
  top: $top;
  right: $right;
  font-size: $font-size;
}

@mixin error-icon($right: 0.5rem, $bottom: 3.5rem, $color: $error) {
  color: $error !important;
  bottom: $bottom;
  right: $right;
}

// =============================================

@each $size, $val in $text-input-sizes {
  .text-input--#{$size} {
    height: $val;
  }
}

@each $size, $val in $radio-input-sizes {
  .radio-input--#{$size} {
    width: $val;
    height: $val;
  }
}

@each $size, $val in $checkbox-input-sizes {
  .checkbox-input--#{$size} {
    width: $val;
    height: $val;

    &::before {
      // upon setting "checked" status of checkbox element, render "check" icon provided by FontAwesome
      font-family: 'Font Awesome 5 Free';
      position: absolute;
      content: '\f00c';
      display: inline-block;
      vertical-align: middle;
      font-weight: 900;
      visibility: hidden;
      @include checkbox-icon(map-get($checkbox-icon-placement-and-sizes, $size)...);
    }
  }
}
