@import "./maps.scss";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Local Mixins
@mixin custom-error($font-size, $font-weight, $line-height, $color, $margin-bottom) {
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  color: $color;
  margin-bottom: $margin-bottom;
}

// Utility Class Definitions
@each $height, $val in $line-heights {
  .line-height--#{$height} {
    line-height: $val;
  }
}

@each $fitProp, $val in $image-fit {
  .img-#{$fitProp} {
    object-fit: $val;
  }
}

@each $size, $val in $font-sizes {
  .font-#{$size} {
    font-size: $val;
  }
}

.visible {
  opacity: 1;
}

.invisible {
  opacity: 0;
}