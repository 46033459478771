@import '../../../scss/variables.scss';

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

.custom-modal {
  z-index: 1035;
  width: 92%;
  max-width: 29.25rem;
  animation: {
    duration: 0.75s;
    fill-mode: forwards;
  }

  &.rounded-sm {
    border-radius: 4px;
  }

  &.rounded-md {
    border-radius: 16px;
  }
}

.date-picker-modal {
  height: 372.47px;
}

.city-picker-modal {
  max-height: 85vh;
}

.custom-modal-fade-in {
  animation-name: modal-fade-in;
}

.custom-modal-fade-out {
  animation-name: modal-fade-out;
}

@keyframes modal-fade-in {
  0% {
    top: 2rem;
    opacity: 0;
  }

  100% {
    top: 5rem;
    opacity: 1;
  }
}

@keyframes modal-fade-out {
  0% {
    top: 5rem;
    opacity: 1;
  }

  100% {
    top: 2rem;
    opacity: 0;
  }
}
