@import './util.scss';

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

.nav-item--mobile {
  border-bottom: 1px solid #555;

  .chevron {
    top: 0.075rem;
  }
}

.nav-item--expandable {
  width: 94%;
  border-radius: 0 !important;
  justify-content: flex-start !important;
}

.expandable-item {
  transition: max-height 0.35s;
}

@include media-breakpoint-up(md) {
  .nav-item--expandable {
    width: 97%;
  }
}

@include media-breakpoint-up(lg) {
  .expandable-menu-items-wrapper--desktop {
    width: 9rem;

    .dropdown-menu-item:hover {
      color: lighten($secondary, 35%) !important;
    }
  }

  .nav-item--desktop {
    &::after {
      position: absolute;
      content: '';
      top: 1.6rem;
      left: 50;
      display: inline-block;
      width: 0%;
      height: 0.1875rem;
      border-radius: 0.09375rem;
      background-color: $primary;
      transition: width 0.3s;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
  }

  .nav-item--button {
    padding: 0.25rem 0.5rem;
    border-radius: 0.3125rem;
    color: $secondary;

    &:hover {
      color: white;
    }
  }

  .caret-down {
    top: -0.025rem;
  }
}
