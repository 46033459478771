@import './scss/variables.scss';

// Required
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

// Optional
@import 'node_modules/bootstrap/scss/reboot';
@import 'node_modules/bootstrap/scss/type';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/grid';
@import 'node_modules/bootstrap/scss/modal';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/close';
@import 'node_modules/bootstrap/scss/badge';
@import 'node_modules/bootstrap/scss/tables';
@import 'node_modules/bootstrap/scss/spinners';
@import 'node_modules/bootstrap/scss/nav';


@import './scss/lazyload.scss';
@import './scss/page-bottom-background.scss';
@import './scss/staticSites.scss';
@import './scss/utilities.scss';

// @font-face {
//   font-family: 'Oswald';
//   src: url('./static/assets/fonts/Oswald/Oswald-VariableFont_wght.ttf');
// }

body {
  font-family: 'Work Sans', 'Oswald', 'Open Sans', 'Helvatica', 'Arial', sans-serif;
}

.container-fluid {
  max-width: 75rem;
  padding: 0 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.font-weight-medium {
  font-weight: 500;
}

.font-oswald {
  font-family: 'Oswald';
}

.font-oswald-regular {
  font-family: 'Oswald Regular';
}

.no-focus-outline {

  &:active,
  &:focus {
    outline: 0;
    border: none;
    outline-style: none;
  }
}

.sub-header-title {
  font-size: 0.6875rem;
  color: #6e6e6e;
}

@include media-breakpoint-up(md) {
  .sub-header-title {
    font-size: 0.8125rem;
    color: #cccccc;
  }
}

.shadow-light {
  box-shadow: 0 0.3125rem 1.25rem #38393829;
}

.visually-hidden {
  visibility: hidden;
}