@import '../../../scss/variables.scss';

.faq-before-title {
  font-weight: 600;
  font-size: 22px !important;
  line-height: 28px;
  color: $primary;
}

.faq-card {
  box-shadow: 0px 5px 16px #38393829;
}
