// ===================== VARIABLE DEFINITIONS =====================
// ************* COLORS *************
$primary: #1ccca5;
$secondary: #323232;
$ternary: #252b37;
$dark-ternary: #1f232d;
$body-bg-secondary: #fafbfc;
$body-bg-ternary: #252525;
$border-primary: #e8eaef;
$dark-text: #545454;
$light-text: #e3e3e3;
$gray-text: #c4c4c4;
$dark-shadow: #080708;
$light-shadow: #383938;
$primary-input-border: #d3d3d3;
$dark-gray: #212529;
$secondary-gray: #6c7b8a;
$ternary-gray: #f4f4f4;
$error: #ec5b62;
$info: #378ef0;
$success: #50e46e;
$warning: #f29423;
$white: #ffffff;

$error-gray-1: #acacac;
$error-gray-2: #707070;

$hover-color: lighten($dark-text, 63%);

// ************* UTILITIES *************
$paragraph-margin-bottom: 0.5rem;
$grid-columns: 24;
$grid-gutter-width: 1rem;
$border-color: $border-primary;
$border-radius-sm: 0.3125rem;
$border-radius: 0.625rem;
$border-radius-lg: 1.25rem;
$box-shadow: 0 0.25rem 0.25rem 0 rgba($light-shadow, 0.16);
// ===================== END VARIABLE DEFINITIONS =====================

// ===================== THEME COLORS MAP DEFINITION =====================
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'ternary': $ternary,
  'dark-ternary': $dark-ternary,
  'error': $error,
  'danger': $error,
  'info': $info,
  'success': $success,
  'warning': $warning,
  'dark-container': $body-bg-ternary,
  'light-container': $body-bg-secondary,
  'dark': $dark-text,
  'light': $light-text,
  'gray': $gray-text,
  'dark-gray': $dark-gray,
  'secondary-gray': $secondary-gray,
  'ternary-gray': $ternary-gray
);
// ===================== END THEME COLORS MAP DEFINITION =====================

// ===================== SPACER MAP DEFINITION =====================
$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
    //4px
  ),
  2: (
    $spacer * 0.5,
    //8px
  ),
  3: (
    $spacer * 0.75,
    //12px
  ),
  4: $spacer,
  //16px
  5:
    (
      $spacer * 1.5,
      //24px
    ),
  6: (
    $spacer * 2,
    //32px
  ),
  7: (
    $spacer * 2.5,
    //40px
  ),
  8: (
    $spacer * 3,
    //48px
  ),
  9: (
    $spacer * 3.5,
    //56px
  ),
  10: (
    $spacer * 3.75,
    //60px
  ),
  11: (
    $spacer * 4,
    //64px
  ),
  12: (
    $spacer * 5,
    //80px
  ),
  13: (
    $spacer * 6,
    //96px
  ),
  14: (
    $spacer * 7,
    //112px
  ),
  15: (
    $spacer * 8,
    //128px
  ),
  16: (
    $spacer * 9,
    //144px
  )
);
// ===================== END SPACER MAP DEFINITION =====================

// ===================== SIZE DEFINITIONS =====================
$sizes: () !default;
$sizes: map-merge(
  (
    10: 10%,
    20: 20%,
    25: 25%,
    30: 30%,
    35: 35%,
    40: 40%,
    45: 45%,
    50: 50%,
    55: 55%,
    60: 60%,
    65: 65%,
    70: 70%,
    75: 75%,
    80: 80%,
    90: 90%,
    100: 100%,
    auto: auto
  ),
  $sizes
);
// ===================== END SIZE DEFINITIONS =====================

// ===================== GRID BREAKPOINTS DEFINITION =====================
$grid-breakpoints: (
  xxs: 0,
  xs: 425,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
  /* xxl: 1440px,
  xxxl: 1920px,
  2k: 2048,
  4k: 4096, */
);
// ===================== END GRID BREAKPOINTS DEFINITION =====================
