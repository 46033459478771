@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

@import '../../scss/variables.scss';

// Local Maps
$animation-images: ("ticket": ticket, "passenger_seat": passenger_seat, "listing": listing, "payment": payment, "customer_info": customer_info);

// Local Mixins
@mixin animation-sprite($id) {
    .animate-#{$id} {
        background: {
            image: url('../../static/assets/images/loading/#{$id}.png');
        };
    }
}

.loading-wrapper {
    top: 0;
    left: 0;
    z-index: 1061;
}

.animation {
    width: 96px;
    height: 96px;
    background: {
        position: left center;
        size: cover;
    };
    animation: play 4s steps(8) infinite;
}

@each $id, $name in $animation-images {
    @include animation-sprite($id);
}

@keyframes play {
    100% {
        background-position: -768px;
    }
}

/***************************************************
***************** LETTER ANIMATION *****************
****************************************************/
@for $i from 0 to 11 {
    .animate-letter-#{$i} {
        animation: {
            name: bounce-letter;
            duration: .5s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}

@keyframes bounce-letter {
    0% {
        transform: translateY(0px);
      }
      40% {
        transform: translateY(-10px);
      }
      80%,
      100% {
        transform: translateY(0px);
      }
}