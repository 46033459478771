@import "../../../scss/variables.scss";

// Change the appearance of default input[type="checkbox"] element provided for each browser
.checkbox-input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  /* Adjusts the position of the checkboxes on the text baseline */
  vertical-align: -2px;
  /* Set here so that Windows' High-Contrast Mode can override */
  color: $primary;

  &:checked::before {
    /* Use `visibility` instead of `display` to avoid recalculating layout */
    visibility: visible;
  }
}

// Utility Classes
.checkbox-input--bordered {
  border: 2px solid $primary;
}

.checkbox-input--no-border {
  border: none;
}