@import '../../../scss/variables.scss';

.radio-input {
  border: 1px solid $primary;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.25rem;
  border-radius: 50%;

  & > div {
    width: 100%;
    height: 100%;
    background-color: $primary;
    border-radius: inherit;
  }

  &-label {
    color: $dark-text;
  }
}
