@import "../scss/utilities.scss";
@import "../scss/variables.scss";

.custom-error {
  background-color: #f5f5f5;
  height: calc(100vh - 3.75rem);

  &-title {
    @include custom-error(9.375rem, bold, 1.17, $error-gray-1, 1.5rem);
  }

  &-sub-title {
    @include custom-error(1.75rem, bold, 1.18, $error-gray-2, .5rem);
  }

  &-text {
    @include custom-error(1.25rem, 400, 1.5, $error-gray-2, 3rem);
  }
}

@include media-breakpoint-up(md) {
  .custom-error {
    &-title, &-sub-title, &-text {
      text-align: center;
    }

    &-title {
      margin-bottom: 0;
    }

    &-image {
      width: 31rem;
      height: 27rem;
      margin: auto;
      margin-top: 3rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .custom-error {
    &-row {
      height: 100%;

      .grid-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

@media(min-width: 1440px) {
  .custom-error {
    &-image {
      width: 44rem;
      height: 38rem;
    }
  }
}