@import 'node_modules/bootstrap/scss/mixins';
@import '../../scss/variables.scss';

#ticket-cancellation-card-section {
  .container {
    overflow: hidden;
    font-size: 14px;
    @include media-breakpoint-up(lg) {
      padding: 10px 5px;
    }

    &.show-content {
      @keyframes show-content-animation {
        from {
          max-height: 55px;
        }
        to {
          max-height: 500px;
        }
      }
      animation-name: show-content-animation;
      animation-duration: 0.5s;
    }

    &.hide-content {
      @keyframes hide-content-animation {
        from {
          max-height: 500px;
        }
        to {
          max-height: 55px;
        }
      }
      max-height: 55px;
      animation-name: hide-content-animation;
      animation-duration: 0.5s;
    }

    &.on-dashboard {
      @include media-breakpoint-down(md) {
        margin-left: -1rem;
        padding-left: 0;
        padding-right: 2px;
      }
      .tcc-item-mobile {
        @include media-breakpoint-down(lg) {
          min-width: 280px;
          max-width: 374px;
        }
        @include media-breakpoint-down(md) {
          margin-left: 1.5rem !important;
        }
      }
    }
  }

  .badge {
    font-size: 12px;
    font-weight: 700;
    padding: 0.3rem 0.5rem;
    min-width: 85px;
  }

  .badge-primary {
    background: rgba(28, 204, 165, 0.15);
    border: 1px solid #1ccca5;
    box-sizing: border-box;
    border-radius: 5px;
    color: $primary;
  }

  .badge-danger {
    background: rgba(236, 91, 98, 0.15);
    border: 1px solid #ec5b62;
    box-sizing: border-box;
    border-radius: 5px;
    color: $error;
  }

  .badge-warning {
    background: rgba(242, 148, 35, 0.15);
    border: 1px solid #f29423;
    box-sizing: border-box;
    border-radius: 5px;
    color: $warning;
  }

  .ticket-cancellation-card-header {
    border-radius: 10px 10px 0px 0px;

    .btn {
      height: 28px;
      line-height: 14px;
    }

    .cancel-button-outline {
      @include media-breakpoint-down(md) {
        padding: 0.25rem 0.25rem;
      }
    }

    .dropdown-menu {
      box-shadow: 0px 4px 4px rgba(56, 57, 56, 0.16);
    }
  }

  .dropdown-menu {
    box-shadow: 0px 4px 4px rgba(56, 57, 56, 0.16);
    @include media-breakpoint-down(md) {
      margin-top: 8px;
      right: 38px;
      z-index: 10;
    }
  }

  .ticket-cancellation-card-content {
    box-shadow: 0px 4px 4px rgba(56, 57, 56, 0.16);
    border-radius: 0px 20px 20px 20px;
  }

  .d-inline-block {
    vertical-align: middle;
  }
}
.ticket-cancellation-card-title {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: rgba(28, 204, 165, 0.13);

  .btn-outline-danger {
    &:hover {
      background-color: darken($error, 30) !important;
    }
    &:disabled {
      background-color: white;
      opacity: 0.35;
      &:hover {
        background-color: white !important;
        color: $error !important;
      }
    }
  }

  &.on-dashboard-desktop {
    background: white;
    max-width: 900px;
    width: 55%;
  }
  &.on-dashboard-mobile {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(56, 57, 56, 0.16);
    border-radius: 0px 10px 0px 0px;
    @include media-breakpoint-down(lg) {
      min-width: 280px;
      max-width: 600px;
    }
    @include media-breakpoint-down(md) {
      border-top-left-radius: 0;
      padding-left: 1.5rem;
    }
  }

  .w-83 {
    width: 83% !important;
    @include media-breakpoint-up(sm) {
      width: 86% !important;
    }
  }

  .w-95 {
    width: 95% !important;
  }
}

.ticket-cancellation-card-content {
  min-height: 6rem;
  border-top-right-radius: 8px;
  box-shadow: 0px 4px 4px rgba(56, 57, 56, 0.16);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ticket-cancellation-card-content-titles {
  color: #c0bfc9;
}

.ticket-cancellation-border {
  border: 1px solid rgba(28, 204, 165, 0.2);
}

.cancelled-ticket {
  color: #323232;
}

.passenger-name-icon {
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  color: $primary;
  height: 32px;
  width: 32px;
  line-height: 32px;
  text-align: center;
  background: rgba(28, 204, 165, 0.3);
  border-radius: 5px;
}

.tcc-item-mobile {
  background-color: #f6f6f7;
  // box-shadow: 0px 4px 4px rgba(56, 57, 56, 0.16);
  border-radius: 5px;
}

.tcc-seat-icon {
  height: 20px;
  width: 25px;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
}

.tcc-departure-icon {
  top: 4px;
  left: 0px;
}

.tcc-road-icon {
  position: absolute;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  border-width: 1.5px;
  left: -5px;
  top: 0;
  width: 19.5px;
}

.tcc-price {
  position: absolute;
  right: 0;
  bottom: 0;
}

.tcc-ticket-id {
  position: absolute;
  bottom: 0;
  right: 75px;
}

.tcc-arrival-icon {
  bottom: 6px;
  left: 0px;
}

.tcc-blank-32 {
  width: 32px;
}

.qr-modal {
  .qr {
    width: 250px;
    &-modal {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.cancel-button-outline {
  height: 15px;
  display: block;
}
