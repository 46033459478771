@import '../../scss/variables.scss';

.popup {
  &-exclamation {
    border-color: #3fc3ee;
    border-style: solid;
    font-size: 3.5rem !important;
    color: #3fc3ee !important;
  }

  &-info {
    font-size: 1.2rem;
    font-weight: 700;
    color: $primary;
    padding: 2px 14px;
    border: 2px solid $primary;
    border-radius: 50%;
  }

  &-wheelchair {
    color: #378ef0 !important;
  }

  &-title {
    color: #595959;
  }

  &-accept-btn {
    background-color: $primary;
    min-width: 8rem;
  }

  &-cancel-btn {
    min-width: 8rem;
    border-color: #d3d3d3;
    span {
      color: #54545499;
    }
  }
}

.logout-modal {
  max-width: 328px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
