@import './util.scss';

.text-input--error {
  border: 1px solid $error !important;
  border-radius: 0.25rem;
}

.error-icon {
  @include error-icon;
}

.error-icon--right-intended {
  @include error-icon(1rem, 3.5rem);
}

.input-error-text {
  font-size: 0.75rem;
  font-weight: 600;
  margin-top: 0.15rem;
  color: #da1e27;
}
