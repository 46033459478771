@import "../../scss/variables.scss";

.toggle-authBox-btn {
  border: 1px solid $secondary;
  border-radius: 50% !important;
  height: 2rem;
  width: 2rem;
  z-index: 20;
  padding-left: 0 !important;
  padding-right: 0 !important;
}