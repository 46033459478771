@import "./util.scss";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.hs-form-footer, .hs-form-landing-banner {
  :is(.input > input) {
    color: #ffffff;
  }
}  

.hs_email {
  @extend %hs-form;
  margin-bottom: .5rem;

  label {
    display: none;
  }

  ul.hs-error-msgs {
    display: none !important;
  }

  .hs-input {
    height: 100%;
    background-color: transparent;
    border: 1px solid $primary-input-border;
    border-radius: .3125rem;
  }
}

.hs_submit {
  @extend %hs-form;

  input[type="submit"] {
    height: 100%;
    background-color: $primary;
    border-radius: .3125rem;
    border: 1px solid $primary;
    color: #ffffff;
    font-size: 0.875rem;
  }
}

.hs_error_rollup {
  .hs-error-msgs {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    font-size: 0.875rem;
    display: flex !important;
    justify-content: center;

    li {
      text-align: center;

      label {
        display: none;
      }
    }
  }
}
@include error-display-default;

.submitted-message {
  color: $primary;
  padding: 0 2rem;
  text-align: center;
}

@each $bp, $right in $form-grids {
  @include media-breakpoint-up($bp) {
    @include error-display-between($right);
  }
}

@include media-breakpoint-up(xl) {
  @include error-display-default;
}