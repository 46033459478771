// Local Maps
$breakpoint-tiers: ("768": 11rem, "1024": 13rem, "1440": 15rem, "1920": 16rem, "2560": 19rem);
$title-line--mobile: ("1": 0.5rem, "2": 2.5rem);
$title-line--tablet: ("1": 0, "2": 3.5rem);
$title-line--desktop: ("1": 0, "2": 4rem);

// Local Placeholders
%title-wrapper {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

//Local Mixins
@mixin title-line($top, $font-size: 1.875rem) {
  top: $top;
  font-size: $font-size;
  z-index: 1;
  font-weight: 700;
}