@import 'node_modules/bootstrap/scss/mixins';
@import '../../../scss/variables.scss';
@import './util.scss';

footer {
  z-index: 0 !important;
  color: #fff;
  font-family: 'Work Sans';

  .copyright-text {
    font: 'Roboto';
    font-size: 12px;
    font-weight: 300;
  }
}

.footer-title {
  font-weight: 700;

  &-lg {
    font-weight: 600;
    font-size: 20px;
    @include media-breakpoint-up(lg) {
      font-size: 22px;
    }
    line-height: 1.2;
  }
}

.footer-item {
  font-size: 14px;
  margin-bottom: 4px;
  a {
    color: #fff;
  }
  mark {
    color: #fff;
  }
}

.footer-download-section {
  img {
    width: 60% !important;
    max-width: 120px !important;
    height: 30px !important;
    border-radius: 4px;
    border: 1px solid #fff;
  }
  .app-store-btn {
    height: auto !important;
  }

  .follow-us-icons {
    position: absolute;
    top: 20px;
    right: 1rem;
  }
}

.footer-logo {
  max-width: 180px;
}

.payment-icon {
  color: rgb(170, 170, 170);
}
