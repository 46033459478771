// Local Placeholders
%backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1034;
}

.backdrop {
  @extend %backdrop;
  background-color: #000000;
  animation: {
    duration: 0.75s;
    fill-mode: forwards;
  }
}

.transparent-bg {
  cursor: default;
  background-color: transparent;
}

.loading-backdrop {
  @extend %backdrop;
  background-color: rgba(255, 255, 255, 0.8);
}

.bd-fade-in {
  animation-name: bd-fade-in;
}

.bd-fade-out {
  animation-name: bd-fade-out;
}

@keyframes bd-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes bd-fade-out {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}
