.phone-input {
  width: 100% !important;
  height: 3rem !important;

  &::placeholder {
    font-size: 0.7rem;
  }
}

.country-list[role='listbox'] {
  top: -13.5rem;
  max-height: 14.5rem;
}

@media (min-width: 768px) {
  .phone-input {
    height: 2.75rem !important;
  }
}
