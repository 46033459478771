@import '../../scss/variables.scss';

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

.app-store-btn {
  height: 3.25rem;
}

.app-store-logo {
  width: 100%;
  height: 100%;
  min-width: 150px;
}
