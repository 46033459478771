@import "../../../scss/variables.scss";

.react-datepicker-wrapper {
  width: 100%;

  input {
    height: 2.4375rem;
    width: 100%;
    padding: 0.5rem;
    border: 1px solid $primary-input-border;
    border-radius: .25rem;
    font-size: 0.875rem;
  }
}

.date-picker-container { // this class is assigned to "calendarClassName" prop of ReactDatePicker component in ReactDatePicker.jsx
  border: 1px solid $primary !important;
}

.react-datepicker__month-select, .react-datepicker__year-select {
  padding: 0.25rem;
  margin-bottom: .75rem;
}

.react-datepicker__close-icon::after {
  background-color: $primary !important;
  color: $secondary !important;
  font-weight: bold !important;
}

