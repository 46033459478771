@import '../../scss/variables.scss';

.react-datepicker {
  padding: 1.5rem 0;
  width: 100%;
  border: none;

  .react-datepicker__navigation {
    top: 2.375rem;
    border-width: 0.7rem;
  }

  .react-datepicker__navigation--previous {
    border-right-color: $primary;
  }

  .react-datepicker__navigation--next {
    border-left-color: $primary;
  }

  .react-datepicker__month-container {
    width: inherit;

    .react-datepicker__header {
      background-color: #fff;
      border-bottom: none;

      .react-datepicker__current-month {
        color: $primary;
        font-size: 1.2rem;
        margin-bottom: 20px;
      }

      .react-datepicker__day-name {
        width: 2.3rem;
        font-weight: bold;
      }
    }

    .react-datepicker__month {
      .react-datepicker__day {
        width: 2.3rem;
        padding: 5px 0;
        border: 1px solid $border-color;
      }

      .react-datepicker__day--selected {
        background-color: $primary  !important;
        color: #fff !important;
      }

      .react-datepicker__day--in-selecting-range {
        background-color: transparent;
        color: #000;
      }
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: inherit;
    color: inherit;
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    border: none;

    &:active,
    &:focus {
      outline: 0;
      border: none;
      outline-style: none;
    }
  }
}