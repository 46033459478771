.ticket-cancellation-modal {
  position: relative;
  margin-top: 20vh;
  transform: translateY(-50%);
  .ticket-container {
    max-height: 40vh;
    overflow-y: auto;
  }
}

.radio-input--lg {
  padding-right: 14px;
}

.table th,
.table td {
  padding: 0.1rem;
}
