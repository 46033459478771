@import '../../scss/variables.scss';

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

.ticket-cancellation-without-login {
  min-height: 40vh;
}

.loading-cancellation-by-ticket-id {
  height: 150px;
}

.loading-cancellation-by-booking-id {
  height: 228px;
}

.ticket-cancellation-by-booking-id-input {
  max-width: 304px;
}

.ticket-cancellation-tab-wrapper {
  max-width: 35rem;
}

.faq-card {
  min-height: 52px;
  max-height: 67px;
  transition: max-height 0.3s;

  &-text {
    transition: opacity 0.3s;
  }
}

.expanded-faq {
  max-height: 52rem;
}

.shrinked-faq {
  &-mobile {
    &-1 {
      max-height: 52px;
    }
    &-2 {
      max-height: 67px;
    }
    &-3 {
      max-height: 87px;
    }
    &-4 {
      max-height: 108px;
    }
  }

  &-desktop {
    @include media-breakpoint-up(md) {
      &-1 {
        max-height: 52px;
      }
      &-2 {
        max-height: 67px;
      }
      &-3 {
        max-height: 67px;
      }
      &-4 {
        max-height: 108px;
      }
    }
  }
}

.bg-primary-light {
  background: #1ccca50d;
}
