@import "../../../scss/variables.scss";

// Local Maps
$icon-sizes: ("sm": 1rem, "md": 1.5rem, "lg": 2rem, "xl": 3rem);

// Utility Class Definitions
@each $size, $val in $icon-sizes {
  .icon-#{$size} {
    width: $val;
    height: $val;
  }
}