@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

// Local Maps
$sections: (
  'subscription': 2,
  'address': 3,
  'payment': 4,
  'copyright': 5
);
$section-columns: (
  'about': 9,
  'links': 10,
  'social-icons': 3
);
$sections-xl: (
  'about': (
    1,
    1,
    4
  ),
  'links': (
    1,
    5,
    4
  ),
  'subscription': (
    1,
    9,
    7
  ),
  'address': (
    1,
    16,
    5
  ),
  'store-buttons': (
    1,
    21,
    3
  ),
  'payment': (
    3
  ),
  'copyright': (
    4
  )
);

// Local Mixins
@mixin border($top, $height) {
  top: $top;
  left: 0;
  height: $height;
  border-bottom: 1px solid #707070;
  z-index: -1;
}

@mixin grid-division($row-start, $col-span: 22) {
  border-top: 1px solid #707070;
  grid-row-start: $row-start;
  grid-column: span $col-span;
}

@mixin grid-column($col-span) {
  grid-column: span $col-span;
}

@mixin grid-layout-xl($row-start, $col-start: 1, $col-span: 24) {
  grid-row-start: $row-start;
  grid-column: $col-start / span $col-span;
}

.border-md-1 {
  @include border(3.25rem, 15rem);
}

.border-md-2 {
  @include border(18.25rem, 6.5rem);
}

.border-md-3 {
  @include border(24.75rem, 5rem);
}

@include media-breakpoint-up(xl) {
  .border-md-1 {
    top: 1.5rem;
  }

  .border-md-2 {
    top: 19rem;
  }
}
