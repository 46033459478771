@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.page-landing-img {
  height: 11rem;
}

@include media-breakpoint-up(md) {
  .page-landing-img {
    height: 25rem;
  }
}