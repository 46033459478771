@import 'node_modules/bootstrap/scss/mixins';
@import '../../../scss/variables.scss';

.tab-view-navbar {
  height: 3rem;
  overflow-x: scroll;

  .city-nav-item:not(:last-of-type) {
    border-right: 1px solid white;
  }
}

.tab-nav-item {
  outline: none;
  width: 60%;
  padding: 0 0.5rem;

  &:first-of-type {
    border-top-left-radius: 8px;
  }

  &:last-of-type {
    border-top-right-radius: 8px;
  }
}

.tab-content {
  border-radius: 0 0 8px 8px;
}

// UTILITY CLASS DEFINITIONS
.tab-selected {
  background-color: $body-bg-secondary;
  box-shadow: 0.25rem 0 0.25rem 0 rgba($body-bg-secondary, 0.08);
}

.tab-unselected {
  background-color: #f1f3f5;
}

@include media-breakpoint-up(md) {
  .tab-view-navbar {
    width: 300px;
    overflow-x: unset;
  }

  .tab-nav-item {
    padding: 0 1rem;
  }
}
