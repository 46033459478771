@import '../../../scss/variables.scss';

header {
  height: 3.75rem;
  z-index: 1033 !important;
}

.roadjet-logo {
  width: 13.375rem;
  height: 3.25rem;
}

.menu-bars {
  position: absolute;
  right: 1rem;
}

.header-placeholder {
  height: 3.75rem;
}
