@import './input-error.scss';
@import './checkbox-input.scss';
@import './date-input.scss';
@import './radio-input.scss';
@import './util.scss';
@import '../../../scss/variables.scss';

.text-input {
  border-radius: 0.25rem;
  border: 1px solid $primary-input-border;
  background-color: $white;
  max-width: 100%;

  &[type='textarea'] {
    resize: none;
    overflow-y: scroll;
  }

  &.disabled {
    cursor: not-allowed;
    background-color: $ternary-gray;
  }
}

::placeholder {
  color: #8d8d8d;
}
