@import "./util.scss";

// Various Icons Style Definitions
.icon-times { // times icon
  width: 1.25rem;
  height: 0.2rem;
  border-radius: .15rem;
  transform: rotateZ(-45deg);

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    background-color: inherit;
    border-radius: .15rem;
    transform: rotateZ(90deg);
  }
}

.rounded-circle {
  border-radius: 50%;
  padding: 1rem 2rem;
  border-width: .3125rem;
}