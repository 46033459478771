@import "../../scss/variables.scss";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.authenticated-user-box {
  position: absolute;
  top: 3.75rem;
  left: 0;
  width: 19.375rem;
  height: 11.25rem;
  box-shadow: 0 0 2px rgba(0,0,0,0.3);
}

.user-profile-photo {
  border: 1px solid #333;
  border-radius: 50%;
  display: inline-flex;
  height: 5.5rem;
  width: 5.5rem;
}

@include media-breakpoint-up(lg) {
  .authenticated-user-box {
    left: unset;
    right: 0;
  }
}