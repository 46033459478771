.static-page--main-title, .static-page--helper-title {
  font-weight: 500;
  line-height: 1.2;
}

.static-page--main-title {
  font-size: 2rem;
}

.static-page--helper-title {
  font-size: 1.75rem;
}